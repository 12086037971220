<template>
  <div
    class="py-4 w-full flex items-center justify-center flex-col text-center h-screen"
  >
    <div class="empty">
      <img src="@/assets/img/empty.png" alt="" />
    </div>
    <h1 class="text-3xl text-gray-700 font-bold mt-12 mb-4">
      You are in space, Nothing to show
    </h1>
    <p class="text-gray-500 text-sm w-84 mx-auto">
      Page wise action button/text will be here with maximum tow line as example
    </p>
  </div>
</template>

<script>
export default {
  name: 'EmptySpace',
}
</script>

<style lang="scss" scoped>
.empty {
  width: 210px;
  height: 195px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
